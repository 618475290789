import React , { useEffect, useState } from "react";
import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
// import { ProductContext } from "../context/product-context";
// import { UserContext } from "../context/user-context";
import { Typography, Box, Container, CircularProgress } from "@mui/material";
import { getDocs, collection } from "firebase/firestore"
import { db } from '../firebase/index'
import {useParams} from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
import Liquidity from "../components/liquidity";
import Solvency from "../components/solvency";
import Overview from "../components/sectorReview";
import Profitability from "../components/profitability";
import Efficiency from "../components/efficiency";
import MarketValue from "../components/marketValue";
import Section from "../components/section";
import Disclaimer from "../components/disclaimers";
import Returns from "../components/returns";
import Cashflow from "../components/cashflow";
import Management from "../components/mgmRatio";
import Ratios from "../components/ratioAnalysis";
import Conclusion from "../components/conclusion";
import Structure from "../components/structure";
import Dividened from "../components/dividend";
import Search from "../components/search";
import { query, orderBy, limit } from "firebase/firestore";
import { Capitalize } from "../components/utils";
import { interpolateColors } from "../theme/colors";

async function fetchProducts(sector){

    const col1 = sector
    const col2 = sector + '2'
    const col3 = sector + '3'
    const col4 = sector + '4'
    const col5 = sector + '5'

    const mypay = {}

    const weeklyRef = collection(db, col1)
    const q = query(weeklyRef, orderBy("at", "desc"), limit(1))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
        // console.log('doc 1')
        mypay['overview'] = doc.data()['sec']
        mypay['liquidity'] = doc.data()['liq']
        mypay['solvency'] = doc.data()['sol']

      })

    const weeklyRef2 = collection(db, col2)
    const q2 = query(weeklyRef2, orderBy("at", "desc"), limit(1))
    const querySnapshot2 = await getDocs(q2)
    querySnapshot2.forEach((doc) => {
            // console.log('doc 2')
            mypay['return'] = doc.data()['ret']
            mypay['profitability'] = doc.data()['prf']
            mypay['efficiency'] = doc.data()['eff']
          })

    const weeklyRef3 = collection(db, col3)
    const q3 = query(weeklyRef3, orderBy("at", "desc"), limit(1))
    const querySnapshot3 = await getDocs(q3)
    querySnapshot3.forEach((doc) => {
            // console.log('doc 3')
            mypay['market'] = doc.data()['mkv']
            mypay['cashflow'] = doc.data()['cfl']
        })

    const weeklyRef4 = collection(db, col4)
    const q4 = query(weeklyRef4, orderBy("at", "desc"), limit(1))
    const querySnapshot4 = await getDocs(q4)
    querySnapshot4.forEach((doc) => {
            // console.log('doc 4')
            mypay['ro'] = doc.data()['mgm']
            mypay['score'] = doc.data()['scr']
        })

    const weeklyRef5 = collection(db, col5)
    const q5 = query(weeklyRef5, orderBy("at", "desc"), limit(1))
    const querySnapshot5 = await getDocs(q5)
    querySnapshot5.forEach((doc) => {
            // console.log('doc 5')
            mypay['dividend'] = doc.data()['dvd']
            mypay['search'] = doc.data()['src']
            mypay['Date'] = doc.id
        })

    return mypay
}

export default function Reportpage() {

    let aaa
    const {sector} = useParams()

    switch (sector) {
        case 'basicmaterials':
            aaa = 'basic materials'
            break
        case 'consumerdefensive':
            aaa = 'consumer defensive'
            break
        case 'communicationservices':
            aaa = 'communication services'
            break
        case 'realestate':
            aaa = 'real estate'
            break
        case 'consumercyclical':
            aaa = 'consumer cyclical'
            break
        default:
            aaa = sector
    }
    
    const colorList = interpolateColors('#00ffff', '#ec13ff', 25)

    const liqCol = [colorList[2], colorList[11], colorList[18]]
    const solCol = [colorList[3], colorList[12], colorList[19]]
    const prfCol = [colorList[4], colorList[13], colorList[20]]

    const Capsec = Capitalize(aaa)
    const [dataDate, setDataDate] = useState('')
    const [dataSec, setDataSec] = useState({})
    const [dataRet, setDataRet] = useState({})
    const [dataLiq, setDataLiq] = useState({})
    const [dataSol, setDataSol] = useState({})
    const [dataPrf, setDataPrf] = useState({})
    const [dataEff, setDataEff] = useState({})
    const [dataMkv, setDataMkv] = useState({})
    const [dataCfl, setDataCfl] = useState({})
    const [dataMgm, setDataMgm] = useState({})
    const [dataCnc, setDataCnc] = useState({})
    const [dataDvd, setDataDvd] = useState({})
    const [dataSrc, setDataSrc] = useState({})
    const [loading, setLoading] = useState(true)
    const [NoComp, setNoComp] = useState()

    useEffect( () => {

        fetchProducts(sector)

        .then( res => {
            setDataDate(res['Date'])
            setDataSec(res['overview'])
            setDataRet(res['return'])
            setDataLiq(res['liquidity'])
            setDataSol(res['solvency'])
            setDataPrf(res['profitability'])
            setDataEff(res['efficiency'])
            setDataMkv(res['market'])
            setDataCfl(res['cashflow'])
            setDataMgm(res['ro'])
            setDataCnc(res['score'])
            setDataDvd(res['dividend'])
            setDataSrc(res['search'])
            setNoComp(res['search']['tickers'].length)
            setLoading(false)

        })

        return( () => {})

    }, [sector])


    return(
        <Layout>
            <Container 
                maxWidth='lg' 
                // sx={{background: theme.palette.background.default}}
                >

                { 
                    loading ? 
                        <>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                width: '100%',
                                height: '85vh'}}>
                                <CircularProgress size={'4rem'}/>
                            </Box>
                        </> 
                    : 
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center'
                        }}>

                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: 800, 
                                pt: '4%',
                                // pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                {Capsec} sector study
                        </Typography>

                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 24, 
                                fontWeight: 600, 
                                pt: '2%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                (As of {dataDate.substring(0,4)}/{dataDate.substring(4,6)}/{dataDate.substring(6,8)})
                        </Typography>

                        <Disclaimer />

                        <Section title={'Introduction'}>
                            <Structure sector={aaa}/>
                        </Section>

                        <Section title={'1. Sector Breakdown'}>
                            <Overview sector={aaa} input={dataSec}/>
                        </Section>

                        <Section title={'2. Sector Return Investigation'}>
                            <Returns sector={aaa} input={dataRet}/>
                        </Section>

                        <Section title={'3. Ratio Analysis'}>
                            <Ratios sector={aaa}/>
                        </Section>

                        <Section title={'3.1. Liquidity Ratios'}>
                            <Liquidity sector={aaa} input={dataLiq} colors={liqCol}/>
                        </Section>

                        <Section title={'3.2. Solvency Ratios'}>
                            <Solvency sector={aaa} input={dataSol} colors={solCol}/>
                        </Section>

                        <Section title={'3.3. Profitability Ratios'}>
                            <Profitability sector={aaa} input={dataPrf} colors={prfCol}/> 
                        </Section>

                        <Section title={'3.4. Efficiency Ratios'}>
                            <Efficiency sector={aaa} input={dataEff}/> 
                        </Section>

                        <Section title={'3.5. Market Value Ratios'}>
                            <MarketValue sector={aaa} input={dataMkv}/> 
                        </Section>

                        <Section title={'3.6. Cashflow Ratios'}>
                            <Cashflow sector={aaa} input={dataCfl}/>
                        </Section>

                        <Section title={'3.7. Management Ratios'}>
                            <Management sector={aaa} input={dataMgm}/>
                        </Section>

                        <Section title={'4. Final Score and Conclusions'}>
                            <Conclusion sector={aaa} input={dataCnc} />
                        </Section>

                        <Section title={'5. Bonus Section'}>
                            <Dividened sector={aaa} input={dataDvd}/>
                        </Section>

                        <Section title={'6. Score Search'}>
                            <Search sector={aaa} input={dataSrc} size={NoComp}/>
                        </Section>

                        <Box sx={{
                            display: 'flex', 
                            justifyContent: 'center',
                            mb: '2rem'
                            }}>

                        </Box>

                    </Box>
                }

            </Container>
        </Layout>
    )
}
