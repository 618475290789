import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { IndustryAverageScore, MetricsStats2 } from './tableblocks';
import { MetricSector, ScoreTop10, ScoreSector, MetricSector2 } from './figureBlocks';
import { SolDef } from "./metricDefinition";

export default function Solvency({children, sector, input, colors}) {

    const [c1, c2, c3] = colors
    const [loading, setLoading] = useState(true)

    const SOLV = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    const industries = input.ind_avg
    const chartweb = input.charts

    let pron

    var items = Object.keys(industries).map(function(key) {
        return [key, industries[key]];
      })
      
    const wValues = items.map(item => item[1].w);

    const solGap = Math.round( 100 * (Math.max(...wValues) - Math.min(...wValues)) ) / 100

    const debt_times = Math.round(
        10 * (Statistics['D/C Ratio']['median'] / (1 - Statistics['D/C Ratio']['median']))
    ) / 10

    const dc_times = Math.round(
        10 * (Statistics['D/C Ratio']['75%'] / Statistics['D/C Ratio']['25%'])
    ) / 10

    if (highScore.data[0] === 10) {
        pron = 'a perfect '
    } else {
        if (highScore.data[0] >= 8.5) {
            pron = 'an impressive '
        } else {
            pron = 'a considerable '
        }
    }

    const [daRatio, setDaRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [daTick, setDaTick] = useState({
        "Series1": []
        }
    )

    const [deRatio, setDeRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [deTick, setDeTick] = useState({
        "Series1": []
        }
    )

    const [dcRatio, setDcRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [dcTick, setDcTick] = useState({
        "Series1": []
        }
    )

    const [solvScore, setSolvScore] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [solvTick, setSolvTick] = useState({
        "Series1": [],
    })

    const c1m = {
        'p':{'min': chartweb.dar.min, 'max': chartweb.dar.max},
        'return': {'min': chartweb.dar.rmin, 'max': chartweb.dar.rmax},
        'user': {'min': -0.25, 'max': 1.0},
    }

    const c2m = {
        'p':{'min': chartweb.der.min, 'max': chartweb.der.max},
        'return': {'min': chartweb.der.rmin, 'max': chartweb.der.rmax},
        'user': {'min': -1., 'max': 2.5},
    }

    const c3m = {
        'p':{'min': chartweb.dcr.min, 'max': chartweb.dcr.max},
        'return': {'min': chartweb.dcr.rmin, 'max': chartweb.dcr.rmax},
        'user': {'min': -0.5, 'max': 1.5},
    }

    useEffect( () => {

        // Blank variables
        let da_ratio_dataset = []
        let da_ratio_tickers = []

        let de_ratio_dataset = []
        let de_ratio_tickers = []

        let dc_ratio_dataset = []
        let dc_ratio_tickers = []

        let solv_score_dataset = []
        let solv_score_tickers = []

        for (const [key, value] of Object.entries(SOLV)) {
            // Solvency ratios
            da_ratio_dataset.push([value[3], value[0], value[4]])
            da_ratio_tickers.push({'ticker': key})
        
            de_ratio_dataset.push([value[3], value[1], value[4]])
            de_ratio_tickers.push({'ticker': key})
           
            dc_ratio_dataset.push([value[3], value[2], value[4]])
            dc_ratio_tickers.push({'ticker': key})
           
            solv_score_dataset.push([value[3], value[8], value[4]])
            solv_score_tickers.push({'ticker': key})
        }

        setDaRatio({
            series: [{  
                        name: 'D/A Ratio',
                        data: da_ratio_dataset,
                    }],
                }
            )
        setDaTick({
            "Series1": da_ratio_tickers,
            }
        )

        setDeRatio({
            series: [{  
                        name: 'D/E Ratio',
                        data: de_ratio_dataset,
                    }],
                }
            )
        setDeTick({
            "Series1": de_ratio_tickers,
            }
        )

        setDcRatio({
            series: [{  
                        name: 'D/C Ratio',
                        data: dc_ratio_dataset,
                    }],
                }
            )
        setDcTick({
            "Series1": dc_ratio_tickers,
            }
        )

        setSolvScore({
            series: [{  
                        name: 'Liquidiy Score',
                        data: solv_score_dataset,
                    }],
                }
            ) 
        setSolvTick({
            "Series1": solv_score_tickers,
        })

        setLoading(false)
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    Having analyzed the liquidity ratios of companies in the sector, we now shift our focus to solvency ratios, which 
                    are vital financial metrics for evaluating a company's ability to meet its long-term financial obligations. These 
                    ratios are crucial for investors and analysts to gauge the financial risk associated with a company. This section
                    explores three key solvency metrics: Debt to Assets (D/A), Debt to Equity (D/E), and Debt to Capitalization (D/C) 
                    ratios. 
                </Typography>

                <Sectionheader head={'3.2.1 Metric Definition'} />

                <SolDef />

                <Sectionheader head={'3.2.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    To align with our methodology, these three ratios for the companies in the {sector} sector were computed and 
                    winsorized at the top and bottom 2 percentiles. <Box fontWeight='fontWeightMedium' display='inline'> Figures 15
                    </Box> to <Box fontWeight='fontWeightMedium' display='inline'>17</Box> presents each ratio versus the company's 1-year 
                    return for all tickers in the sector.

                    Reader must note that very low solvency ratios do not inherently signal a positive or negative verdict. For 
                    instance, a low D/A ratio signifies that the company has chosen to prioritize capital generation through 
                    issuing stocks to investors rather than obtaining loans. A lower ratio means lower interest payments, but it 
                    also means less profit left for owners, as shareholders have a claim to some of the company's earnings. It's 
                    worth noting that what's considered a good debt to asset ratio can vary between sectors and industries. 
                    Capital-intensive industries like manufacturing or sector such as utilities might naturally have higher D/A 
                    ratios due to the substantial investments required in assets.
                </Typography>

                <MetricSector2 data={daRatio} name={''} ticks={daTick} colors={[c1, c2]} limits={c1m}/>

                <Figurecaption caption={'Figure 15: D/A ratio vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    A negative D/E ratio indicates negative shareholder equity, implying that the company's liabilities surpass 
                    its assets. Generally, such a scenario is regarded as indicative of substantial risk and may prompt consideration 
                    of seeking bankruptcy protection. 
                </Typography>

                <MetricSector2 data={deRatio} name={''} ticks={deTick} colors={[c2, c3]} limits={c2m}/>

                <Figurecaption caption={'Figure 16: D/E ratio vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    A high D/C ratio indicates a greater reliance on debt financing, while a lower ratio suggests a more 
                    equity-oriented capital structure. A lower D/C ratio can also indicate that a company is better positioned 
                    to handle financial downturns or market uncertainties since a smaller proportion of its capital is tied to 
                    debt that needs servicing. However, what is considered a good ratio differs among industries. For example, 
                    capital-intensive sectors like utilities may have higher acceptable D/C ratios due to the long-term nature of 
                    their investments.
                </Typography>

                <MetricSector2 data={dcRatio} name={''} ticks={dcTick} colors={[c3, c1]} limits={c3m}/>

                <Figurecaption caption={'Figure 17: D/C ratio vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In general, while having a low solvency ratio might seem like a positive sign on the surface, excessively low 
                    debt ratios can also be detrimental. Some level of debt provides financial flexibility. Interest on debt is 
                    tax-deductible, which can reduce the overall cost of capital for the company. Debt can magnify the return on 
                    equity. In Addition, investors and creditors often assess a company's use of debt as a sign of confidence in 
                    its operations. Moreover, by sensibly using debt to finance projects or investments, a company can potentially 
                    enhance shareholder value. If the return on investment exceeds the cost of debt, it can contribute positively
                    to shareholder wealth. <Box fontWeight='fontWeightMedium' display='inline'>Table 4</Box> presents the statistics 
                    of the solvency metric distributions, offering perspectives into the solvency norms and the capital structure 
                    of the companies in the sector.
                </Typography>

                <Figurecaption caption={'Table 4: Statistics of solvency ratios distribution'} />

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats2 table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The statistics tell a compelling narrative about the {sector} sector. On average, companies in this sector hold
                    <Box fontWeight='fontWeightMedium' display='inline'> ${1000 * Statistics['D/A Ratio']['median']}</Box> in debt 
                    for every $1,000 in assets on their balance sheets. Also, they carry <Box fontWeight='fontWeightMedium' 
                    display='inline'> ${1000 * Statistics['D/E Ratio']['median']}</Box> in debt for every $1,000 in shareholder's 
                    equity, signifying that their total debt amounts to <Box display='inline' fontWeight='fontWeightMedium'>
                        {debt_times}</Box> times their total equity. Last but not least, average debt to capitalization of a company 
                    at 75<sup>th</sup> percentile of D/C distribution is <Box display='inline' fontWeight='fontWeightMedium'>
                    {dc_times}</Box> times of a company in 25<sup>th</sup> percentile.
                </Typography>

                <Sectionheader head={'3.2.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Similar to the previous section, a score between <Box fontWeight='fontWeightMedium' display='inline'>0.5</Box> to <Box 
                        fontWeight='fontWeightMedium' display='inline'>10</Box> assigned to each company for every solvency metric. Companies 
                        with lower ratios compare to their peers are associated with higher scores. However, the scoring system still panelizes 
                        companies with very low ratios for various reasons that some of them were expressed in previous section. <Box 
                        fontWeight='fontWeightMedium' display='inline'>Figure 18</Box> demonstrates  
                        the calculated total (composite) solvency score for the companies across the {sector} sector. The total 
                        score is calculated as the weighted average of the individual solvency metric, according to their significance and the 
                        sector's dynamic. 
                </Typography>

                <ScoreSector series={solvScore} name={''} labels={solvTick} color={c2} limits={[chartweb.rtot.min, chartweb.rtot.max]}/>

                <Figurecaption caption={'Figure 18: Total solvency scores vs. 1-year return ' + sector + ' sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Figure 19</Box> offers representation of the composite solvency scores for the sector's top 
                    10 companies. Notably, ticker <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[0]}</Box> takes the lead with {pron} 
                    overall score of <Box fontWeight='fontWeightMedium' display='inline'>{highScore.data[0]}</Box>. 
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 19: Top 10 companies with highest total solvency score'} />

                <Sectionheader head={'3.2.4 Solvency Score: Industry Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 4.A</Box> displays both the simple and market 
                    capitalization-weighted averages of the solvency scores for industries within the {sector} sector. This 
                    table visually demonstrates how different industries manage their debt within the sector. As readers can 
                    observe, the difference in market capitalization-weighted average solvency scores among industries 
                    reaches <Box fontWeight='fontWeightMedium' display='inline'>{solGap}</Box>, underscoring the diverse 
                    capital structures and financial strategies in the sector. 
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 12, md:14}, fontWeight: '600', lineHeight: 1.5}}>
                    Table 4.A: Solvency ratios averages across the industries
                </Typography>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <IndustryAverageScore header={['Industry', 'Simple', 'Wighted']} data={items} />
                </Box>

            </Box>
            }
        </div>
    )

}