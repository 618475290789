import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/userContext";
import Layout from '../components/layout';
import { Typography, Box, Container, CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { httpsCallable } from "firebase/functions";
import { db, payments, functions } from "../firebase/index";
import { getCurrentUserPayments, getCurrentUserSubscriptions } from "@invertase/firestore-stripe-payments";
import ManagementBlock from "../components/managementBlock";
import Subscriptions from "../components/subscriptions";
import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";
import ButtonBlock from "../components/buttonBlock";

async function userTransactions() {
    const allPromise = Promise.all([
        getCurrentUserPayments(payments),
        getCurrentUserSubscriptions(
            payments, 
            {status: ["active", "trialing"]}
        ),
    ])

    return await allPromise
}

async function AC(user) {
    const userRef = doc(db, "customers", user.uid)
    const docSnap = await getDoc(userRef)

    const subRef = collection(db, "customers", user.uid, "subscriptions")
    const q = query(subRef, where("status", "in", ["active", "trialing"]))
    const querySnapshot = await getDocs(q)

    // const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"))
    const subs = []
    querySnapshot.forEach((doc) => {
        subs.push(doc.data())
        // console.log(doc.id, " => ", doc.data());
      });
    
    // return docSnap.data()
    return [subs, docSnap.data()]
}

export default function Userpage() {

    const theme = useTheme()
    const { user } = UserAuth()

    const AM = httpsCallable(functions, 'allowME')

    const [portal, setPortal] = useState(null)
    const [activeSubs, setActiveSubs] = useState([])
    const [mypad, setMypad] = useState('40vh')
    const [subLoading, setSubLoading] = useState(true)
    const [stripeID, setStripeId] = useState(null)

    useEffect(() => {

        if (user) {

            AC(user)
            .then( res => {
                // res has to elements:
                // 1. active subs [array]
                // 2. user info
                setActiveSubs( res[0] )
                if (res[0].length <= 5) {
                    setMypad({xs: '35vh', md: '20vh'})
                    setSubLoading(false)
                } else {
                    if (res[0].length <= 8) {
                        setMypad({xs: '20vh', md: '10vh'})
                        setSubLoading(false)
                    } else {
                        setMypad({xs: '15vh', md: '5vh'})
                        setSubLoading(false)
                    }
                }
                const ID = res[1].stripeId
                setStripeId(res.stripeId)

                    Promise.all([
                        AM({stipeID: ID}), 
                        userTransactions()]
                    )
                    .then(
                        res => {

                            setPortal(res[0].data.portal.url)

                        }
                    )
                    .catch( (error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log('Error code: ', errorCode)
                        console.log('Error Msg.: ', errorMessage)
                    })
                }
            )
            .catch( (error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error code: ', errorCode)
                console.log('Error Msg.: ', errorMessage)
            })

        } else {

            console.log('HERE is the else condition')            
        }

        return(() => {console.log('User page REFRESHED!')})

    }, [user])

    return(
        <Layout>
            <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>

                {
                    (stripeID == null && portal == null) 
                    ? 
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                    : 
                    <>

                        <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center'}}>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 36, md: 48}, 
                                    fontWeight: 800, 
                                    pt: '4%',
                                    pb: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                My Account
                            </Typography>
                        </Box> 

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            }}>

                            <ManagementBlock portal={portal}/>

                            <ButtonBlock title={'Stock Opportunities'} buttonText={'Check'} destination={"/stocks"}/>

                            <ButtonBlock title={'Congress Trades'} buttonText={'View'} destination={"/congress"}/>

                            <ButtonBlock title={'VC/HF Filings'} buttonText={'Form 13'} destination={'/13f'}/>

                            <Subscriptions subLoading={subLoading} activeSubs={activeSubs} />

                        </Box>

                        <Box sx={{height: mypad}}></Box> 

                    </>
                }

            </Container>
        </Layout>
    )

}