import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Divider, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from "@mui/material";

export default function StockTable({data, columns, size, mb, bgc}) {

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0)
    }

    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            width: size,
            mb: mb,
            }}>
            <Paper elevation={1} sx={{ width: '100%', mb: 2, borderRadius: '0.5rem' }}>
            <TableContainer 
                sx={{
                    // minWidth: 650,
                    maxHeight: '40vh',
                    // overflow: 'scroll', 
                    // border: 1, 
                    // borderRadius: '0.5rem'
                }}>

                <Table stickyHeader aria-label="simple table">

                    <TableHead>
                        <TableRow selected={true}>
                            {columns.map((column) => (
                                // console.log(column.width)
                                <TableCell
                                    key={column.dataKey}
                                    variant="head"
                                    align={column.numeric || false ? 'right' : 'left'}
                                    style={{ width: column.width }}
                                    sx={{
                                        fontWeight: 900, 
                                        backgroundColor: bgc,
                                    }}
                                    >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => { return(
                            <TableRow
                                hover 
                                role="checkbox" 
                                tabIndex={-1} 
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                {
                                // ['office', 'symbol', 'assetDescription', 'amount', 'transactionDate', 'dateRecieved']
                                columns.map(key => (
                                    // console.log(key.numeric)
                                    <TableCell 
                                        component="th" 
                                        scope="row"
                                        key={key.dataKey}
                                        align = {key.numeric ? 'right' : 'left'}
                                        >
                                        {row[key.dataKey]}
                                    </TableCell>
                                ) )
                                }
                            </TableRow>
                        )})}
                    </TableBody>

                </Table>

            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 75]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>
        </Box>
    );
}