import React from "react";
import { Typography, Box } from "@mui/material";

export default function CapStone({title}) {

    return(
        <Box sx={{
            display: 'flex',
            width: '100%', 
            alignItems: 'center', 
            justifyContent: 'center'}}>

            <Typography 
                textAlign={'center'} 
                sx={{
                    fontSize: {xs: 36, md: 48}, 
                    fontWeight: 800, 
                    pt: '4rem',
                    width: '100%', 
                    height: '100%'}}>
                {title}
            </Typography>

        </Box>
    )
}