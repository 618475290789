import React, { useEffect, useState } from "react";
// import { UserAuth } from "../context/userContext";
import Layout from '../components/layout';
import { 
    Box, 
    Container, 
    TextField,
    Button,
    Divider,
    Typography} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { functions } from "../firebase/index";
// import { ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import CapStone from "../components/capStone";
// import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { HoldingFigure } from "../components/figureBlocks";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/ClearOutlined';

function Ace(res) {

    // Determinig number of holdings in the response.
    let dat, AUM
    const holdings = res.data.holdings.length

    if (holdings >= 20) {
        dat = res.data.holdings.slice(0, 20)
        AUM = res.data.holdings.reduce((sum, item) => sum + item.value, 0)
    } else {
        console.log('second case')
        dat = res.data.holdings
        AUM = res.data.holdings.reduce((sum, item) => sum + item.value, 0)
    }

    // extracting each position value, issuer and index.
    const holdingValue = dat.map(dict => dict.value)
    const holdingName = dat.map(dict => dict.issuer)
    const holdingCats = dat.map((dict, index) => index + 1)

    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                borderRadiusApplication: 'end',
                borderRadius: 5,
                distributed: true,
            }
        },
        dataLabels: {
                enabled: false
        },
        legend: {
            show: false,
        },
        tooltip: {
            x: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return holdingName[dataPointIndex];
                }
            },
            y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                    return '$' + holdingValue[dataPointIndex].toLocaleString();
                }
            },
        },
        xaxis: {
            type: 'category',
            categories: holdingCats,
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            logarithmic: false,
            logBase: 10,
            tickAmount: 10,
            axisBorder: {
                show: true,
            },
            labels: {
                formatter: function(value) {
                    return value.toLocaleString()
                },
            },
            // max: 10,
            // min: 0,
        },
    }

    return { 'portfolio': holdingValue, 'options': options, 'noh': holdings, 'aum': AUM}
    
}

export default function Holdings() {

    const theme = useTheme()

    // State parameters
    const [cik, setCik] = useState("")
    const [fetched, setFetched] = useState(false)
    const [positions, setPositions] = useState(0)
    const [aum, setAum] = useState(0)
    const [suffix, setSuffix] = useState('M')
    const [divis, setDivis] = useState(1e6)
    const [chartSeries, setChartSeries] = useState({
        series: [{
                name: 'Holdings',
                data : [],
            }
        ],
    })
    const [chartOptions, setChartOptions] = useState({
        options: {
            chart: {
                type: "bar",
            },
        },
    })

    // Remote function
    const getHoldings = httpsCallable(functions, 'getHoldings')

    // Internal functions
    const handleCikChange = (e) => {
 
        const cn = e.target.value

        console.log(cn)

        setCik(cn)

    }
    const clearSearch = () => {
        setCik("")
        setChartSeries({
            series: [{
                name: 'Holdings',
                data : [],
            }],
        })
        setChartOptions({
            options: {
                chart: {
                    type: "bar",
                },
            },
        })
        setFetched(false)
      };

    useEffect( () => {

        console.log(chartSeries)

        return ( () => {})

    }, [fetched, chartSeries, chartOptions])

    return (
        <Layout>
            <Container 
                maxWidth='lg' 
                sx={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    background: theme.palette.background.default,
                    }}>

                <CapStone title='Venture Holdings Check'/>

                <Box sx={{
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: {xs: '100%', md: '80%'}, 
                    mt: fetched ? '2rem' : '10rem',
                    mb: fetched ? '1rem' : '10rem',
                    border:1,
                    borderRadius: 2}}>

                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', mt: '1rem', mb: '1rem'}}>
                            <Typography                                
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 14, md: 18}, 
                                    fontWeight: 600, 
                                    fontFamily: 'inherit',
                                    // color: grey[600],
                                    pt: '1rem',
                                    pl: '1rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                Current API collects the latest Form 13F-HR (Holding Report) from SEC's EDGAR.
                            </Typography>
                        </Box>

                        <Box sx={{width: '80%'}}>
                            <Divider flexItem variant='middle'/>
                        </Box>

                        <Box sx={{
                            display: 'flex', 
                            flexDirection: {xs: 'column', md: 'row'}, 
                            mt: {xs: '2rem', md: '1rem'},
                            mb: {md: '1rem'},
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            width: '80%', 
                            height: '100%'}}>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center', 
                                width: {xs: '90%', sm: '75%', md: '60%'}, 
                                // background: 'pink',
                                height: '100%'}}>
                                <TextField 
                                    label="CIK No." 
                                    variant="outlined"
                                    onChange={handleCikChange} 
                                    value={cik}
                                    // defaultValue="Small"
                                    size="small"
                                    sx={{width: '80%'}}
                                    InputProps={{
                                        endAdornment: (
                                        <IconButton onClick={clearSearch} edge="end" sx={{visibility: cik ? 'visible' : 'hidden'}}>
                                            <ClearIcon />
                                        </IconButton>
                                        )
                                    }}/>
                            </Box>

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                // background: 'blue',
                                width: {xs: '90%', sm: '75%', md: '60%'},
                                mt: {xs: '2rem', md: 0}, 
                                mb: {xs: '2rem', md: 0}, 
                                height: '100%'}}>

                                <Button 
                                    variant='contained'
                                    disableElevation
                                    sx={{width: '80%'}}
                                    onClick={(e)=> {
                                        // const data = {'cik': cik}
                                        getHoldings({'cik': cik})
                                        .then((res) => { 
                                            // console.log(res.data.holdings)
                                            setFetched(false)
                                            const findings = Ace(res)
                                            setChartOptions(findings.options)
                                            setChartSeries({
                                                series: [{
                                                    data : findings.portfolio,
                                                }],
                                            })
                                            setPositions(findings.noh)
                                            setAum(findings.aum)
                                            Math.log10(findings.aum) >= 9 ? setSuffix('B') : setSuffix('M')
                                            Math.log10(findings.aum) >= 9 ? setDivis(1e9) : setDivis(1e6)
                                            setFetched(true)

                                        })

                                    }}
                                    // sx={{mt: '2rem', mb: '2rem'}}
                                    >
                                    Check Latest Holdings
                                </Button>

                            </Box>
                        </Box>
                </Box>

                <HoldingFigure fetched={fetched} series={chartSeries} options={chartOptions} />

                <Box sx={{
                    display: fetched ? 'flex': 'none', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: {xs: '100%', md: '80%'}, 
                    mb: '4rem',
                    border:1,
                    borderRadius: 2}}>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%', mt: '1rem', mb: '1rem'}}>
                        <Typography                                
                            textAlign={'center'} 
                            sx={{
                                fontSize: {xs: 16, md: 20}, 
                                fontWeight: 600, 
                                fontFamily: 'inherit',
                                // color: grey[600],
                                pt: '1rem',
                                pl: '1rem',
                                width: '100%', 
                                height: '100%'}}>
                            Portfolio Summary and Metrics
                        </Typography>
                    </Box>

                    <Box sx={{width: '80%', pb: '1rem'}}>
                        <Divider flexItem variant='middle' />
                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%'}}>
                        <Box sx={{display: 'flex', width: {xs: '140%', md: '125%'}}}>
                            <Typography                                
                                textAlign={'left'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pl: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                Assets Under Management (AUM):
                            </Typography>
                        </Box>
                        <Divider flexItem orientation='vertical' variant='middle' />
                        <Box sx={{display: 'flex', width: {xs: '60%', md: '75%'}}}>
                            <Typography                                
                                textAlign={'right'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pr: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                    ${(Math.round(10 * aum / divis) / 10).toLocaleString()}{suffix}
                            </Typography>
                        </Box>

                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%'}}>

                        <Box sx={{display: 'flex', width: {xs: '140%', md: '125%'}}}>
                            <Typography                                
                                textAlign={'left'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pl: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                Total reported positions:
                            </Typography>
                        </Box>
                        <Divider flexItem orientation='vertical' variant='middle' />
                        <Box sx={{display: 'flex', width: {xs: '60%', md: '75%'}}}>
                            <Typography                                
                                textAlign={'right'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pr: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                {positions}
                            </Typography>
                        </Box>

                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%'}}>

                        <Box sx={{display: 'flex', width: {xs: '140%', md: '125%'}}}>
                            <Typography                                
                                textAlign={'left'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pl: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                Largest position portfolio share:
                            </Typography>
                        </Box>
                        <Divider flexItem orientation='vertical' variant='middle' />
                        <Box sx={{display: 'flex', width: {xs: '60%', md: '75%'}}}>
                            <Typography                                
                                textAlign={'right'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pr: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                {Math.round(100 * chartSeries.series[0].data[0] / aum)} %
                            </Typography>
                        </Box>

                    </Box>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%', mb: '1rem'}}>

                        <Box sx={{display: 'flex', width: {xs: '140%', md: '125%'}}}>
                            <Typography                                
                                textAlign={'left'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pl: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                Top 10 position portfolio share:
                            </Typography>
                        </Box>
                        <Divider flexItem orientation='vertical' variant='middle' />
                        <Box sx={{display: 'flex', width: {xs: '60%', md: '75%'}}}>
                            <Typography                                
                                textAlign={'right'} 
                                sx={{
                                    fontSize: {xs: 12, md: 16}, 
                                    fontWeight: 400, 
                                    fontFamily: 'inherit',
                                    lineHeight: 2,
                                    // color: grey[600],
                                    // pt: '1rem',
                                    pr: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                    {Math.round(100 * chartSeries.series[0].data.slice(0, 9).reduce((sum, item) => sum + item, 0) / aum)} %
                            </Typography>
                        </Box>

                    </Box>

                </Box>

            </Container> 
        </Layout>
    )
}