// import logo from './logo.svg';
// import './App.css';
import React from "react";
import { Routes, Route } from 'react-router-dom'
import Homepage from './pages/homepage';
import Signin from './pages/signin';
import Signup from './pages/signup';
import Userpage from "./pages/userpage";
import NotFound from './pages/not-found';
import PrivateRoute from './components/privateRoute'
import Shop from './pages/shop';
import Cancel from './pages/cancelpage'
import Success from "./pages/success";
import About from './pages/about';
import Sample from './pages/samplepage';
import Solutions from './pages/solutionpage';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Contact from './pages/contactpage';
import Report from './pages/reportpage';
import ForgetPass from "./pages/forgetpage";
import Opportinities from "./pages/stocks";
import Etabs from "./pages/etabs";
import Congress from "./pages/congress";
import Holdings from "./pages/holdings";

function App() {

    return (
        <div>

            <Routes>

                <Route exact path='/' element={<Homepage />} />
                <Route path='/signin' element={<Signin />}/>
                <Route path='/signup' element={<Signup />}/>
                <Route path='/forget' element={<ForgetPass />}/>
                <Route path='/user' element={<PrivateRoute><Userpage/></PrivateRoute>}/>
                <Route path='/:sector' element={<PrivateRoute><Report/></PrivateRoute>}/>
                <Route path='/stocks' element={<PrivateRoute><Opportinities/></PrivateRoute>}/>
                <Route path='/congress' element={<PrivateRoute><Congress/></PrivateRoute>}/>
                <Route exact path='/about' element={<About />} />
                <Route path='/shop' element={<Shop />} />
                <Route path='/sample' element={<Sample />} />
                <Route path='/solutions' element={<Solutions />} />
                
                <Route path='/etabs' element={<PrivateRoute><Etabs /></PrivateRoute>} />
                <Route path='/13f' element={<PrivateRoute><Holdings /></PrivateRoute>} />

                <Route path='/privacy' element={<Privacy />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/success' element={<Success />} />
                <Route path='/cancel' element={<Cancel />} />
                <Route path='*' element={<NotFound />} />

            </Routes>
        </div>
    );
}

export default App;
