import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, AccordionDetails, Container } from "@mui/material";
import { alpha, useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Link } from "react-router-dom";
import StockTable from "../components/stockTable";
import Layout from '../components/layout';

async function getSenate() {
    const senate = await fetch('https://financialmodelingprep.com/api/v4/senate-trading-rss-feed?page=0&apikey=f4030f9f983d0335bb9ccb0732636faa')
    // .then(response => response.json())
    // .then(data => {
    //     return data
    // })
    return senate.json()
}

async function getHouse() {
    const house = await fetch('https://financialmodelingprep.com/api/v4/senate-disclosure-rss-feed?page=0&apikey=f4030f9f983d0335bb9ccb0732636faa')
    // .then(response => response.json())
    // .then(data => {
    //     return data
    // })
    return house.json()
}

export default function Congress() {

    const [senSales, setSenSales] = useState([])
    const [senPurch, setSenPurch] = useState([])

    const [husSales, setHusSales] = useState([])
    const [husPurch, setHusPurch] = useState([])

    useEffect( () => {

        Promise.all([getSenate(), getHouse()])
        .then(res => {
            setSenSales(
                res[0].filter(entry => entry.type === "Sale" || entry.type === "Sale (Partial)" || entry.type === "Sale (Full)")
            )
            setSenPurch(
                res[0].filter(entry => entry.type === "Purchase")
            )
            setHusSales(
                res[1].filter(entry => entry.type === "Sale" || entry.type === "Sale (Partial)" || entry.type === "Sale (Full)")
            )
            setHusPurch(
                res[1].filter(entry => entry.type === "Purchase")
            )
        })
        .catch(error => console.error(error));

        return( () => {} )

    }, [])

    const theme = useTheme()

    const columns = [
        {
            label: 'Office',
            dataKey: 'office',
            width: '150px',
            numeric: false,
        },
        {
            label: 'Name',
            dataKey: 'assetDescription',
            width: '200px',
            numeric: false,
        },
        {
            label: 'Symbol',
            dataKey: 'symbol',
            numeric: false,
        },
        {
            label: 'Amount ($)',
            dataKey: 'amount',
            numeric: true,
        },
        {
            label: 'Transacted',
            dataKey: 'transactionDate',
            width: '100px',
            numeric: true,
        },
        {
            label: 'Reported',
            dataKey: 'dateRecieved',
            width: '100px',
            numeric: true,
        },
    ]

    const husColumns = [
        {
            label: 'Representative',
            dataKey: 'representative',
            width: '150px',
            numeric: false,
        },
        {
            label: 'Name',
            dataKey: 'assetDescription',
            width: '200px',
            numeric: false,
        },
        {
            label: 'Symbol',
            dataKey: 'ticker',
            width: '20px',
            numeric: false,
        },
        {
            label: 'Amount ($)',
            dataKey: 'amount',
            width: '120px',
            numeric: true,
        },
        {
            label: 'Transacted',
            dataKey: 'transactionDate',
            width: '80px',
            numeric: true,
        },
        {
            label: 'Reported',
            dataKey: 'disclosureDate',
            width: '80px',
            numeric: true,
        },
    ]

    const senColor = '#27d8ff'
    const husColor = '#c43bff'

    return(
        <Layout>
            <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    minHeight: '65vh'
                    }}>
                
                    {/* Page Header */}
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center', 
                        justifyContent: 'center'}}>

                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: {xs: 36, md: 48}, 
                                fontWeight: 800, 
                                pt: '4rem',
                                // pb: '4rem',
                                width: '100%', 
                                height: '100%'}}>
                            Congress Trades
                        </Typography>

                    </Box>

                    {/* Senate Accord. */}
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        pt: '2rem',
                        pb: '2rem',
                        alignItems: 'center', 
                        justifyContent: 'center'}}>

                        <Accordion variant='outlined' sx={{width: '70%'}}>

                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{background: senColor}}
                                >
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 30, md: 36}, 
                                        fontWeight: 700,
                                        color: grey[800],
                                        width: '100%', 
                                        height: '100%'}}>
                                    Senate Trades
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>

                                <Divider flexItem variant='middle' />

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    ml: '2.5%'
                                    }}>
                                    <Typography 
                                        textAlign={'left'} 
                                        sx={{
                                            fontSize: {xs: 24, md: 30}, 
                                            fontWeight: 600,
                                            color: grey[600], 
                                            pt: '2rem',
                                            pb: '2rem',
                                            width: '100%', 
                                            height: '100%'}}>
                                        Recent Purchases
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center', 
                                    justifyContent: 'center'}}>
                                    <StockTable data={senPurch} columns={columns} size={'95%'} mb={'0rem'} bgc={senColor}/>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    ml: '2.5%'
                                    }}>

                                    <Typography 
                                        textAlign={'left'} 
                                        sx={{
                                            fontSize: {xs: 24, md: 30}, 
                                            fontWeight: 600,
                                            color: grey[600], 
                                            pt: '2rem',
                                            pb: '2rem',
                                            width: '100%', 
                                            height: '100%'}}>
                                        Recent Sales
                                    </Typography>

                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center', 
                                    justifyContent: 'center'}}>
                                    <StockTable data={senSales} columns={columns} size={'95%'} mb={'0rem'} bgc={senColor}/>
                                </Box>

                            </AccordionDetails>

                        </Accordion>

                    </Box>

                    {/* House Accord. */}
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        pt: '2rem',
                        pb: '2rem', 
                        justifyContent: 'center'}}>

                        <Accordion variant='outlined' sx={{width: '70%'}}>

                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{background: husColor}}
                                >
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 30, md: 36}, 
                                        fontWeight: 700,
                                        width: '100%', 
                                        height: '100%'}}>
                                    House Trades
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>

                            <Divider flexItem variant='middle' />

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                ml: '2.5%'
                                }}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 24, md: 30}, 
                                        fontWeight: 600,
                                        color: grey[600], 
                                        pt: '2rem',
                                        pb: '2rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    Recent Purchases
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center', 
                                justifyContent: 'center'}}>
                                <StockTable data={husPurch} columns={husColumns} size={'95%'} mb={'0rem'} bgc={husColor}/>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                ml: '2.5%'
                                }}>

                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 24, md: 30}, 
                                        fontWeight: 600,
                                        color: grey[600], 
                                        pt: '2rem',
                                        pb: '2rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    Recent Sales
                                </Typography>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center', 
                                justifyContent: 'center'}}>
                                <StockTable data={husSales} columns={husColumns} size={'95%'} mb={'0rem'} bgc={husColor}/>
                            </Box>

                            </AccordionDetails>

                        </Accordion>

                    </Box>

                        
                </Box>
            </Container>
        </Layout>
    )
}