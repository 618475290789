import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/userContext";
import Layout from '../components/layout';
import { Typography, Box, Container, Divider, Select, CircularProgress, InputLabel, FormControl, NativeSelect, MenuItem } from "@mui/material";
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import NativeSelect from '@mui/material/NativeSelect';
// // import { alpha } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
// // import theme from "../theme/theme";
// import { httpsCallable } from "firebase/functions";
import { db, payments, functions } from "../firebase/index";
import { Timestamp } from "firebase/firestore"
// // import { getCurrentUserSubscriptions, getCurrentUserPayments } from "@stripe/firestore-stripe-payments";
// import { getCurrentUserPayments, getCurrentUserSubscriptions } from "@invertase/firestore-stripe-payments";
// // import { grey } from "@mui/material/colors";

// // import { buttonColors, contrastTextColors } from '../theme/colors';
// // import ManagementBlock from "../components/managementBlock";
// // import ActiveSubBlock from "../components/activeSubBlock";
// // import Subscriptions from "../components/subscriptions";
import { doc, getDoc, getDocs, collection, query, where, orderBy, limit } from "firebase/firestore";
import { grey } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

async function trades(user, sector) {
    

    const sectorCol = "Trade_" + sector

    let docId;
    const q = query(collection(db, sectorCol), orderBy("timestamp", "desc"), limit(1))
    const snapShot = await getDocs(q)
    snapShot.forEach((doc) => {
        docId = doc.id
        // console.log(docId)
        });
    const colRef = collection(db, sectorCol, docId, "Runs")
    const lastEntry = query(colRef, orderBy("timestamp", "desc"), limit(1))
    const querySnapshot = await getDocs(lastEntry)
    const lastTrends = []
    querySnapshot.forEach((doc) => {
        lastTrends.push(doc.data())
      });
    
    return lastTrends
}

function createData(
    key, values
  ) {
    const time = values.lastCrossing
    // const time = new Timestamp(values.lastCrossing/1e9,0).toDate().toLocaleDateString('en-US', {
    //     timeZone: 'America/New_York',
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     hour: "2-digit",
    //     minute: 'numeric',
    //   })
    return { key, values, time };
  }

export default function Opportinities() {

    const theme = useTheme()
    const { user } = UserAuth()

    const [mypad, setMypad] = useState([])
    const [loading, setLoading] = useState(true)
    const [sector, setSector] = useState('Util')

    const handleSectorChange = (event) => {
        setSector(event.target.value);
    };

    useEffect(() => {

        if (user) {

            // 
            trades(user, sector)
            .then(
                res => {
                    // console.log(res)
                    const payload = res[0]
                    // console.log(payload)
                    delete payload.timestamp
                    const rows = []
                    Object.keys(payload).map((key) => {
                        rows.push(createData(key, payload[key]))
                        // console.log(createData(key, payload[key]))
                    })
                    console.log(rows)
                    setMypad(rows)
                    setLoading(false)
                }
            )
            .catch( (error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Error code: ', errorCode)
                console.log('Error Msg.: ', errorMessage)
            })

        } else {
            console.log('HERE is the else condition')            
        }

        return(() => {console.log('Stock page REFRESHED!')})

    }, [sector, user])

    return(
        <Layout>
            <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>
                { 
                    loading ? 

                    // Loading wheel
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                    : 
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100%',
                        }}>

                        {/* Title Block */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center'}}>

                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 36, md: 48}, 
                                    fontWeight: 800, 
                                    pt: '4rem',
                                    // pb: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                Stock Opportunities
                            </Typography>

                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 20, md: 26}, 
                                    fontWeight: 700, 
                                    color: grey[700],
                                    pt: '2rem',
                                    width: '100%', 
                                    height: '100%'}}>
                                Algorithm Release Date: 2/8/2025
                            </Typography>

                        </Box>

                        {/* Descriptions */}
                        <Box sx={{
                            display: 'flex',
                            background: 'white',
                            width: '75%',
                            flexDirection: 'column',
                            mt: '1rem',
                            border: 1, 
                            borderRadius: '0.5rem',
                            borderColor: grey[400],
                            }}>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 16, md: 20}, 
                                        fontWeight: 500, 
                                        // color: grey[700],
                                        pt: '1rem',
                                        pl: '0.5rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    Equity Screening Criteria:
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '1rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    1. Algo is based on hourly stock price and runs daily on 9,11,13,15:45 New York time zone.
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '1rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    2. It scans stocks listed on NYSE, AMEX, NASDAQ and OTC exchanges.
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '1rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    3. Minimum ticker price is $20 and minimum exchange volume is 50,000.
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 16, md: 20}, 
                                        fontWeight: 500, 
                                        // color: grey[700],
                                        pt: '1rem',
                                        pl: '0.5rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    Quantitative Selection Conditions:
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '1rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    1. Algorithm signals "buy" when there are concurent price, volume and trend confirmations.
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '2rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    1.A. PRICE: When both short (42) and medium (77) EMAs are both ascending.
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '2rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    1.B. TREND: TII is ascending and higher tahn signal, or it is in +95/-10 zones.
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pl: '2rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    1.C. VOLUME: Price is higher than volume-weighted price average..
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Typography 
                                    textAlign={'left'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 400, 
                                        color: grey[600],
                                        pt: '1rem',
                                        pb  : '1rem',
                                        pl: '1rem',
                                        width: '100%', 
                                        height: '100%'}}>
                                    2. Listed results have a "buy" signal that is confirmed in the past two hours.
                                </Typography>
                            </Box>

                        </Box> 

                        {/* Sector Selector */}
                        <Box sx={{
                            width: '75%', 
                            pt: '3rem', 
                            pb: '5rem'}}>
                            <FormControl fullWidth>
                                <InputLabel variant='outlined' htmlFor="uncontrolled-native">
                                    Sector
                                </InputLabel>
                                <NativeSelect
                                    value={sector}
                                    onChange={handleSectorChange}
                                    input={<BootstrapInput />}
                                    inputProps={{
                                        name: 'Sector',
                                        id: 'uncontrolled-native',
                                    }}>
                                <option value={'Util'}>
                                    <Typography sx={{pl: '1rem'}}>
                                        Utilities
                                    </Typography>
                                </option>
                                <option value={'BaMa'}><Typography sx={{pl: '1rem'}}>Basic Materials</Typography></option>
                                <option value={'Ener'}><Typography sx={{pl: '1rem'}}>Energy</Typography></option>
                                <option value={'Tech'}><Typography sx={{pl: '1rem'}}>Technology</Typography></option>
                                <option value={'CoSe'}><Typography sx={{pl: '1rem'}}>Communication Services</Typography></option>
                                <option value={'CoCy'}><Typography sx={{pl: '1rem'}}>Consumer Cyclical</Typography></option>
                                <option value={'CoDe'}><Typography sx={{pl: '1rem'}}>Consumer Defensive</Typography></option>
                                <option value={'Indu'}><Typography sx={{pl: '1rem'}}>Industrial</Typography></option>
                                <option value={'Fina'}><Typography sx={{pl: '1rem'}}>Financial</Typography></option>
                                </NativeSelect>
                            </FormControl>
                        </Box>

                        {/* Table Element */}
                        <Box sx={{
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            width: '75%',
                            // height: '20rem',
                            mb: '5rem',
                            }}>
                            <TableContainer 
                            sx={{border: 1, borderRadius: '0.5rem'}}
                            // component={Paper}
                            >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow selected={true}>
                                        <TableCell  sx={{fontWeight: 900}}>Company Name</TableCell>
                                        <TableCell variant='head' align="right" sx={{fontWeight: 900}}>Symbol</TableCell>
                                        <TableCell align="right" sx={{fontWeight: 900}}>Price&nbsp;($)</TableCell>
                                        <TableCell align="right" sx={{fontWeight: 900}}>Crossing Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mypad.map((row) => (
                                        <TableRow
                                        key={row.values.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.values.name}
                                        </TableCell>
                                        <TableCell align="right">{row.key}</TableCell>
                                        <TableCell align="right">{row.values.priceCrossing}</TableCell>
                                        <TableCell align="right">{row.time}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Box>

                    </Box>
                }
            </Container>
        </Layout>
    )
}