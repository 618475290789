import React from "react";
import { Typography, Box, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { buttonColors } from '../theme/colors';
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function ManagementBlock(props) {

    const theme = useTheme()

    return(

        <Box sx={{ 
            display: 'flex', 
            flexDirection: {xs: 'column', sm: 'row'},
            alignItems: 'center', 
            justifyContent: 'center',
            width: '90%',
            border: 0.5,
            borderColor: theme.palette.border.main,
            borderRadius: '0.4rem',
            background: 'white',
            }}>

            <Box sx={{
                width: {xs: '100%', sm: '125%'},
                ml: {sm: '5%'},
                mt: {xs: '8%', sm: '6%'},
                mb: {xs: '4%', sm: '6%'},
                }}>
                <Typography 
                    textAlign={{xs: 'center', sm: 'left'}}  
                    sx={{
                        width: '100%',
                        fontSize: {xs: 20, sm: 26}, 
                        fontWeight: 500,
                        color: grey[700] 
                        }}>
                    Payments & Subscriptions
                </Typography>
            </Box>

            <Divider flexItem variant='middle' orientation='vertical' />

            <Divider flexItem variant='middle'/>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: {xs: 'center', md: 'right'},
                justifyContent: 'center',
                width: {xs: '100%', sm: '55%'},
                mt: {xs: '4%', sm: '6%'},
                mb: {xs: '8%', sm: '6%'},
                }}>
                <Button 
                    variant='outlined'
                    component={Link}
                    to={props.portal} 
                    sx={{
                        mr: {sm: '6.7%'},
                        maxWidth: {xs: '280px', sm: '180px'},
                        pt: '2%',
                        pb: '2%',
                        width: '80%',
                        color: "white",
                        borderColor: alpha(buttonColors[11], 0.5),
                        background: alpha(buttonColors[11], 0.7),
                        fontFamily: 'inherit',
                        '&:hover': {
                            color: "white",
                            borderColor: alpha(buttonColors[11], 0.5),
                            background: alpha(buttonColors[10], 0.9),
                        },
                    }}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: {xs: 12, sm: 14},
                                fontWeight: 400,
                                }}>
                            Manage
                        </Typography>
                    
                </Button>
            </Box>
        </Box>
    )
}