import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
import { Figurecaption } from './reportBlocks';
import { RatioPie } from "./figureBlocks";
import { Sectionheader } from "./reportBlocks";

export default function Ratios({sector}) {

    const [loading, setLoading] = useState(true)

    useEffect( () => {

        setLoading(false)
        return( () => {})

    }, [])

    return(
       <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
                : 
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%',}}>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Ratio analysis is a key tool for assessing a company’s financial health and comparing its performance to
                        competitors. It examines the relationships between financial statement elements like assets, liabilities, 
                        equity,
                        revenue, and expenses, offering key insights into the company’s operation, efficiency and stability. This
                        method helps investors, creditors, and management make informed decisions by spotting industry trends,
                        comparing performance to sector’s benchmarks, and assessing the company’s ability to meet its goals. This
                        study analyzes seven (7) ratio categories 
                        illustrated in <Box fontWeight='fontWeightMedium' display='inline'>Figure 9</Box>, each evaluating a core 
                        aspect of financial performance, such as revenue generation, profitability, or debt management.
                    </Typography>

                    <Box sx={{
                        border:1,
                        borderRadius: 2,
                        background: 'white',
                        width: {xs: '95%', sm: '85%', md: '65%', lg: '50%'}, 
                        height: {xs: '400px', sm: '440px'},
                        minWidth: '375px'
                        }}>
                        <Box sx={{m: 1, height: '100%'}}>
                            {RatioPie()}
                        </Box>
                    </Box>

                    <Figurecaption caption={'Figure 9: Breakdown of ratio analysis'} />

                    <Sectionheader head={'3.0.1 Study Layout'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        This chapter’s seven analysis sections follow a consistent format. Each starts by defining key financial metrics
                        and explaining their use. The metrics are then calculated and compared across all companies in the {sector} sector. 
                        To ensure reliable results, winsorization is applied to reduce the impact of outliers. Key
                        statistical values, including median, mean, standard deviation, 25<sup>th</sup> and 75<sup>th</sup> percentile values, as well as the
                        weighted average of each individual metric’s distribution are provided. These items offer a useful benchmark
                        for comparing company performance.

                        {/* Analysis sections of this chapter have consistent layout. Each section begins by introducing financial 
                        metrics, detailing their definitions, and outlining their applications. Subsequently, these metrics are computed and 
                        presented for all companies within the {sector} sector, facilitating a comprehensive comparison and evaluation 
                        of their financial performance. To mitigate the impact of outliers, winsorization techniques are employed, ensuring 
                        the robustness of the analyses. Various statistical parameters for each metric are calculated and presented, 
                        providing readers with a practical benchmark for comparing the performance of any company in the sector. */}
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        In our studies, we favor median over mean as representative of the sector wide average, because it is not
                        sensitive to extreme values (outliers) and forms a robust statistic. For a metric which typically varies within a
                        narrow range, it can be significantly skewed by just a few very large outliers in the distribution, leading to a
                        higher and potentially misleading value. In contrast, the median represents the middle value of a data set,
                        ensuring that each data point is given equal weight regardless of its magnitude.
                    </Typography>

                    <Sectionheader head={'3.0.2 Scoring System'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        To enable sector-wide comparisons, we’ve developed a proprietary scoring system. Current scoring system
                        (<Box fontWeight='fontWeightMedium' display='inline'>FARSIGHT2.3 {'\u00AE'}</Box>) assigns scores ranging 
                        from <Box fontWeight='fontWeightMedium' display='inline'>0.5</Box> to <Box fontWeight='fontWeightMedium' 
                        display='inline'>10</Box> to each metric, using a proprietary algorithm that
                        models the joint distribution of each metric and a company’s performance, specifically trained on {sector} 
                        sector historical data. What differentiates this system from other competitors is that, in addition to
                        the unique algorithm, the data used to prepare each study is refreshed on a weekly basis, the inputs to the
                        models are survivorship bias-free, and last but not least, sector and industry-wide benchmarks are unique.

                        {/* To enable sector-wide comparisons, we've developed a proprietary scoring system. This system assigns scores ranging 
                        from <Box fontWeight='fontWeightMedium' display='inline'>0.5</Box> to <Box fontWeight='fontWeightMedium' 
                        display='inline'>10</Box> to each metric, using a proprietary algorithm that establishes the joint distribution of 
                        the metric and annual returns across the sector, while also considering accepted practical values. This process is 
                        repeated for three (3) metrics in each category. Subsequently, a composite score is calculated for each company, 
                        representing the weighted average of individual scores within its ratio category. This composite score serves as a 
                        key indicator of the company's overall financial health in the given area and its performance relative to its peers. 
                        The distribution of composite scores versus 1-year returns across the sector is presented at the end of each section. 
                        Current study uses <Box fontWeight='fontWeightMedium' display='inline'>FARSIGHT2.2 {'\u00AE'}</Box> scoring methodology. */}
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        The scoring process is repeated for three (3) metrics in each category. Subsequently, a composite score is
                        calculated for each company, representing the weighted average of individual metric scores. This composite
                        score serves as a key indicator of the company’s overall financial performance in the given category and
                        relative to its peers. The distribution of composite scores versus 1-year returns across the sector is presented
                        at the end of each section.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        After assessing each company’s performance across seven ratio categories, a final score is calculated as a
                        weighted average of the category scores. The weights are determined by a machine learning algorithm that
                        evaluates the significance of each metric in the company’s sector-wide performance. Companies are then
                        ranked based on their final scores, with the top performers identified and listed on a weekly basis.
                        This study is dedicated to delivering clear, insightful analyses that improve readers’ understanding of sector-
                        wide valuations, benchmarks, and performance criteria. By examining company rankings within the {sector} sector, 
                        readers gain granular insights at the industry level, identify outliers, and pinpoint areas where
                        performance lags or leads the rest of the industry. These comprehensive analyses not only highlight broader
                        trends but also provide a clear view of each company’s standing relative to its peers, thereby establishing
                        definitive benchmarks and evaluation standards.

                        {/* After evaluating the performance of each company across the sector in seven ratio categories, a final score is assigned 
                        to each company. This score is calculated as the weighted average of the scores in each financial category. The weights 
                        for each component are determined through a machine learning algorithm, which assesses the significance of each metric 
                        in relation to the sector-wide performance of each company. Companies are then ranked based on their final scores, with 
                        the top performers identified and listed on a weekly basis. These comprehensive analyses not only help to understand each 
                        company's financial standing but also identify broader sector trends. By analyzing how companies in the {sector} sector 
                        perform across these ratio categories, readers can gain useful knowledge into industry dynamics and pinpoint areas for 
                        improvement. This study is committed to delivering clear and insightful analyses that contribute to a better understanding 
                        of the sector. */}
                    </Typography>

                </Box>
            }
        </div>
    )

}