import React , { useEffect, useState } from "react";
// import Layout from "../components/layout";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';

// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { storage } from '../firebase/index';
// import { ref, getDownloadURL } from "firebase/storage";
// import Chart from "react-apexcharts";
import { LiqDef } from "./metricDefinition";
import { Figurecaption, Sectionheader } from './reportBlocks';
import { IndustryAverageScore, MetricsStats2 } from './tableblocks';
import { ScoreTop10, ScoreSector, MetricSector2 } from './figureBlocks';

export default function Liquidity({children, sector, input, colors}) {

    const [c1, c2, c3] = colors

    const LIQS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    const industries = input.ind_avg
    const chartweb = input.charts

    var items = Object.keys(industries).map(function(key) {
        return [key, industries[key]];
      })

    const inventory_percent = Math.round(
        (Statistics['Current Ratio']['mean'] - Statistics['Quick Ratio']['mean']) * 100) / 100

    const cash_limit = Math.round(
        Statistics['Cash Ratio']['75%'] / Statistics['Cash Ratio']['25%'] * 100) / 100

    var language
    if (highScore.data[0] === 10) {
        language = 'Perfect'
    } else {
        if (highScore.data[0] < 10 && highScore.data[0] >= 9) {
            language = 'Spectacular'
        } else {
            language = 'Total'
        }
    }

    const [loading, setLoading] = useState(true)

    const [current, setCurrent] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [curTick, setCurTick] = useState({
        "Series1":[]
        }
    )

    const [quick, setQuick] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [qucTick, setQucTick] = useState({
        "Series1": []
        }
    )

    const [cash, setCash] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [cashTick, setCashTick] = useState({
        "Series1": []
        }
    )

    const [liquidity, setLiquidity] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [liqTick, setLiqTick] = useState({
        "Series1": [],
    })

    const c1m = {
        'p':{'min': chartweb.cur.min, 'max': chartweb.cur.max},
        'return': {'min': chartweb.cur.rmin, 'max': chartweb.cur.rmax},
        'user': {'min': -0.5, 'max': 4.5},
    }

    const c2m = {
        'p':{'min': chartweb.qur.min, 'max': chartweb.qur.max},
        'return': {'min': chartweb.qur.rmin, 'max': chartweb.qur.rmax},
        'user': {'min': -0.5, 'max': 4.5},
    }

    const c3m = {
        'p':{'min': chartweb.car.min, 'max': chartweb.car.max},
        'return': {'min': chartweb.car.rmin, 'max': chartweb.car.rmax},
        'user': {'min': -0.25, 'max': 3.5},
    }

    useEffect(() => {

        // EAMPLE: Replacing the chart with figure
        // const pathReference = ref(
        //     storage, 
        //     'gs://farsight-report.appspot.com/technology/20240204/Cash Ratio'
        //     );
        // getDownloadURL(pathReference)
        // .then( (url) => {setCAR(url)})

        // Current ratios
        let current_ratio_dataset = []
        let current_ratio_tickers = []
        // Quick ratios
        let quick_ratio_dataset = []
        let quick_ratio_tickers = []
        // Cash ratios
        let cash_ratio_dataset = []
        let cash_ratio_tickers = []
        // Liuidity Scores
        let liq_score_dataset = []
        let liq_score_tickers = []
        for (const [key, value] of Object.entries(LIQS)) {
            // Current ratios
            current_ratio_dataset.push([value[3], value[0], value[4]])
            current_ratio_tickers.push({'ticker': key})
            // Quick ratios
            quick_ratio_dataset.push([value[3], value[1], value[4]])
            quick_ratio_tickers.push({'ticker': key})
            // Cash ratios
            cash_ratio_dataset.push([value[3], value[2], value[4]])
            cash_ratio_tickers.push({'ticker': key})
            // Liquidity Score
            liq_score_dataset.push([value[3], value[8], value[4]])
            liq_score_tickers.push({'ticker': key})
        }

        setCurrent({
            series: [{  
                        name: 'Current Ratio',
                        data: current_ratio_dataset,
                    }],
                }
            )
        setCurTick({
            "Series1": current_ratio_tickers,
            }
        )

        setQuick({
            series: [
                    { 
                        name: 'Quick Ratio', 
                        data: quick_ratio_dataset, 
                    },
                ],
            })
        setQucTick({
            "Series1": quick_ratio_tickers,
        })

        setCash({
            series: [
                    { 
                        name: 'Cash Ratio', 
                        data: cash_ratio_dataset, 
                    },
                ],
            })
        setCashTick({
            "Series1": cash_ratio_tickers,
        })

        setLiquidity({
            series: [{  
                        name: 'Liquidiy Score',
                        data: liq_score_dataset,
                    }],
                }
            )
        setLiqTick({
            "Series1": liq_score_tickers,
        })

        setLoading(false)

        return () => {}
    }, [ ])


    return(

        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    This chapter focuses on liquidity ratios, which are financial metrics used to evaluate a company's ability to meet its 
                    short-term financial obligations. They provide an indication of how well a company manages its current assets and 
                    liabilities. Current assets are a category of assets that are expected to be converted into cash or used up within one 
                    year or one operating cycle (whichever is longer). These assets are considered liquid, meaning they can be easily converted 
                    to cash. Creditors use liquidity ratios to make informed lending decisions, while investors use them to evaluate the 
                    company's financial stability and potential as a sound investment. The three most commonly used liquidity ratios are the 
                    current, quick, and cash ratios. 
                </Typography>

                <Sectionheader head={'3.1.1 Metric Definition'} />

                <LiqDef />

                <Sectionheader head={'3.1.2 Liquidity ratio distributions'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Three above-mentioned metrics were computed and winsorized at both ends of their distributions. This
                    approach allows readers to get an accurate snapshot of the sector’s baselines and identify potential outlier
                    companies. <Box fontWeight='fontWeightMedium' display='inline'> Figures 10</Box> to <Box fontWeight='fontWeightMedium' 
                    display='inline'>12 </Box> plot each liquidity metric and the company’s one-year return within the sector, while
                    the marker size is determined by the logarithm of each company’s market capitalization (MCAP) in millions
                    of dollars.
                </Typography>

                <Figurecaption caption={'Figure 10: Current ratios vs. 1-year return across the sector'} />

                <MetricSector2 data={current} name={''} ticks={curTick} colors={[c1, c2]} limits={c1m}/>

                <Figurecaption caption={'Figure 10: Current ratios vs. 1-year return across the sector'} />

                <MetricSector2 data={quick} name={''} ticks={qucTick} colors={[c2, c3]} limits={c2m}/>

                <Figurecaption caption={'Figure 11: Quick ratios vs. 1-year return (winsorized)'} />

                <MetricSector2 data={cash} name={''} ticks={cashTick} colors={[c3, c1]} limits={c3m}/>

                <Figurecaption caption={'Figure 12: Cash ratios vs. 1-year return across the sector'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 3</Box> summarizes key statistical parameters of each 
                    liquidity metrics, including median, mean, and the weighted average across the sector. These statistical values 
                    provide readers with an understanding of the typical range of liquidity ratios within the consumer cyclical sector.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 12, md:14}, fontWeight: '600', lineHeight: 1.5}}>
                    Table 3: Statistics of liquidity ratios distribution
                </Typography>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats2 table={Statistics}/>
                </Box>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    For instance, on average, companies hold <Box fontWeight='fontWeightMedium' display='inline'>${
                    Statistics['Cash Ratio']['median']}</Box> in cash and cash equivalents and <Box fontWeight='fontWeightMedium' 
                    display='inline'>${Statistics['Current Ratio']['median']}</Box> in current assets for every dollar in their current 
                    liabilities. Inventories, on average, constitute <Box fontWeight='fontWeightMedium' display='inline'>{Math.round(
                        100*inventory_percent)}%</Box> of companies current assets. Readers can conclude that a company at a company 
                    at 75 percentile of cash ratio distribution, carries cash on it’s book roughly <Box fontWeight='fontWeightMedium' 
                    display='inline'>{cash_limit}</Box> times of a company at 25 percentile.
                </Typography>

                <Sectionheader head={'3.1.3 Liquidity Score: Sector Overview'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        FARSIGHT scoring system assigns a score ranging from <Box fontWeight='fontWeightMedium' display='inline'>0.5
                            </Box> to <Box fontWeight='fontWeightMedium' display='inline'> 10</Box> to each liquidity metric. These 
                        scores are computed by a proprietary algorithm that is specifically trained on {sector} sector historical
                        data. What sets this system apart from competitors is its novel scoring algorithm, weekly updates, and the
                        use of survivorship bias-free inputs in the models. A composite (total) liquidity score is then computed by
                        calculating the weighted average of theese individual scores. <Box fontWeight='fontWeightMedium' display='inline'
                        >Figure 13</Box> illustrates the distribution of total liquidity scores against the winsorized 1-year returns 
                        for the entire sector.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        It’s important to note that current scoring system penalizes companies with very high liquidity ratios for
                        various reasons. A very high liquidity ratio, particularly when it includes a substantial amount of cash and 
                        cash equivalents, may suggest that the company is not efficiently utilizing its resources. The funds held in
                        highly liquid assets could have been invested in projects (such as research and development) or assets with
                        higher returns. This lack of investment might impact the company’s overall profitability and growth potential.
                </Typography>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Additionally, it could indicate a deficiency in strategic planning or an inability to identify profitable investment
                    opportunities, or taking technological or production edge over their peers in the same industry as perceived by
                    analysts or investors. Lastly, it’s worth noting that inflation erodes the purchasing power of money over time.
                </Typography>

                <ScoreSector series={liquidity} name={''} labels={liqTick} color={c2} limits={[chartweb.rtot.min, chartweb.rtot.max]}/>

                <Figurecaption caption={'Figure 13: Composite (total) liquidity scores vs. 1-year return ' + sector + ' sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Figure 14</Box> shows the top 10 companies with highest 
                        total liquidity score. As of writing, ticker <Box fontWeight='fontWeightMedium' display='inline'>{
                        highScore.names[0]}</Box> is ranked first among these companies, with a {language} liquidity score of <Box 
                        fontWeight='fontWeightMedium' display='inline'>{highScore.data[0]}</Box>. Tickers <Box fontWeight='fontWeightMedium' 
                        display='inline'>{highScore.names[1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[2]}
                        </Box> rank second and third with corresponding liquidity scores of <Box fontWeight='fontWeightMedium' 
                        display='inline'>{highScore.data[1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{
                            highScore.data[2]}</Box>, respectively.
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={'Liquidity score'}/>

                <Figurecaption caption={'Figure 14: Top 10 companies with highest liquidity score'} />

                <Sectionheader head={'3.1.4 Liquidity Score: Industry Overview'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Both simple (mean) and market capitalization-weighted average total liquidity scores were calculated for
                        industries within the {sector} sector and are presented in <Box fontWeight='fontWeightMedium' display='inline'>
                            Table 3.A</Box>. 
                        These metrics offer deeper insight into peer performance and highlight the influence of market capitalization on 
                        companies within each industry. If the weighted average is higher than the simple average (mean), it indicates that 
                        higher liquidity ratios are associated with larger market capitalizations. This means the data is likely skewed to 
                        the right, with more weight given to higher values.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 12, md:14}, fontWeight: '600', lineHeight: 1.5}}>
                    Table 3.A: Liquidity ratios averages across the industries
                </Typography>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <IndustryAverageScore header={['Industry', 'Simple', 'Wighted']} data={items} />
                </Box>
                {/* <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Companies with highest composite liquidity score across the {sector} sector are listed in <Box fontWeight='fontWeightMedium' display='inline'>
                    Table ZZ</Box>.
                </Typography>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['Current', 'Quick', 'Cash']}/>
                </Box> */}

            </Box>
            }
        </div>
    )
}