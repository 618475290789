import React from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

export default function ButtonBlock({title, buttonText, destination}) {

    const theme = useTheme()

    return(
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            width: '90%',
            border: 0.5,
            borderColor: theme.palette.border.main,
            borderRadius: '0.4rem',
            background: 'white',
            mt: '2rem',
            }}>

            <Box sx={{
                width: '100%',
                mt: '1rem',
                mb: '1rem',
                }}>
                <Typography 
                    textAlign={{
                        xs: 'center', 
                        sm: 'left'}}  
                    sx={{
                        ml: {sm: '5%'},
                        width: '100%',
                        fontSize: {xs: 20, sm: 26}, 
                        fontWeight: 500,
                        color: grey[700] 
                        }}>
                        {title}
                    {/* Senate Latest Trades */}
                </Typography>
            </Box>

            <Divider flexItem variant='middle' orientation='vertical' />

            <Box sx={{
                display: 'flex',
                width: '45%',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '1rem',
                mb: '1rem',
                }}>
                <Button 
                    component={Link} 
                    // to="/congress"
                    to={destination} 
                    variant='contained' 
                    disableElevation 
                    sx={{mt: '1rem', mb: '1rem', width: '55%'}} 
                    >
                    {buttonText}
                    {/* Cong. Trades */}
                </Button>
            </Box>

        </Box>
    )

}