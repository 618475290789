import React , { useEffect, useState } from "react";
// import Layout from "../components/layout";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';

// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { auth, db } from '../firebase/index';
// import Chart from "react-apexcharts";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { IndustryAverageScore, MetricsStats2, } from './tableblocks';
import { MetricSector2, ScoreSector, ScoreTop10 } from './figureBlocks';
import { PrfDef } from "./metricDefinition";

export default function Profitability({children, sector, input, colors}) {

    const [c1, c2, c3] = colors
    const [loading, setLoading] = useState(true)

    const PROFS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    const industries = input.ind_avg
    const chartweb = input.charts

    var items = Object.keys(industries).map(function(key) {
        return [key, industries[key]];
      })

    let pron, lang1, lang2
    if (highScore.data[0] === 10) {
        pron = 'a perfect '
    } else {
        if (highScore.data[0] >= 8.5) {
            pron = 'an impressive '
        } else {
            pron = 'a considerable '
        }
    }

    if (Statistics['GPM'].median >= 0) {
        lang1 = 'gain'
    } else {
        lang1 = 'loss'
    }

    if (Statistics['NPM'].median >= 0) {
        lang2 = 'gain'
    } else {
        lang2 = 'loss'
    }

    let opv, opm_wm
    if (Statistics['OPM'].median >= 0) {
        opv = Math.round( 100 * Statistics['OPM'].median) /100
    } else {
        opv = `(${Math.abs(Math.round( 100 * Statistics['OPM'].median) /100)})`
    }

    if ( Statistics['OPM'].wav >= 0 ) {
        opm_wm = Math.round( 100 * Statistics['OPM'].wav) /100
    } else {
        opm_wm = `(${Math.abs(Math.round( 100 * Statistics['OPM'].wav) /100)})`
    }

    let maxS = -Infinity;
    let minS = Infinity;
    let maxI, minI

    for (const key in industries) {
        if (industries[key].w > maxS) {
            maxS = industries[key].w;
            maxI = key
        }
        if (industries[key].w < minS) {
            minS = industries[key].w;
            minI = key
        }
    }

    const stds = Math.round(100 * Statistics['OPM'].std) / 100

    const opm_times = Math.round(
        10 * (Statistics['OPM']['75%'] / Statistics['OPM']['median'])
    ) / 10

    const npm_times = 100 * Math.round(
        100 * (Statistics['NPM']['median'] / Statistics['OPM']['median'])
    ) / 100

    const [gpmRatio, setGpmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [gpmTick, setgpmTick] = useState({
        "Series1": []
        }
    )

    const [opmRatio, setOpmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [opmTick, setopmTick] = useState({
        "Series1": []
        }
    )

    const [npmRatio, setNpmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [npmTick, setnpmTick] = useState({
        "Series1": []
        }
    )

    const [profitability, setProfitability] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [profTick, setProfTick] = useState({
        "Series1": []
        }
    )

    const c1m = {
        'p':{'min': chartweb.gpm.min, 'max': chartweb.gpm.max},
        'return': {'min': chartweb.gpm.rmin, 'max': chartweb.gpm.rmax},
        'user': {'min': -0.5, 'max': 1.5},
    }
    
    const c2m = {
        'p':{'min': chartweb.opm.min, 'max': chartweb.opm.max},
        'return': {'min': chartweb.opm.rmin, 'max': chartweb.opm.rmax},
        'user': {'min': -0.5, 'max': 1.5},
    }

    const c3m = {
        'p':{'min': chartweb.npm.min, 'max': chartweb.npm.max},
        'return': {'min': chartweb.npm.rmin, 'max': chartweb.npm.rmax},
        'user': {'min': -0.5, 'max': 1.5},
    }


    useEffect( () => {
        let gpm_ratio_dataset = []
        let gpm_ratio_tickers = []

        let opm_ratio_dataset = []
        let opm_ratio_tickers = []

        let npm_ratio_dataset = []
        let npm_ratio_tickers = []

        let prof_score_dataset = []
        let prof_score_tickers = []
        for (const [key, value] of Object.entries(PROFS)) {
            
            gpm_ratio_dataset.push([value[3], value[0], value[4]])
            gpm_ratio_tickers.push({'ticker': key})

            opm_ratio_dataset.push([value[3], value[1], value[4]])
            opm_ratio_tickers.push({'ticker': key})
            
            npm_ratio_dataset.push([value[3], value[2], value[4]])
            npm_ratio_tickers.push({'ticker': key})

            prof_score_dataset.push([value[3], value[8], value[4]])
            prof_score_tickers.push({'ticker': key})
        }

        setGpmRatio({
            series: [{  
                    name: 'GPM Ratio',
                    data: gpm_ratio_dataset,
                    }],
                }
            )
        setgpmTick({
            "Series1": gpm_ratio_tickers,
        })

        setOpmRatio({
            series: [{  
                name: 'OPM Ratio',
                data: opm_ratio_dataset,
            }],            
        })
        setopmTick({
            "Series1": opm_ratio_tickers,
        })

        setNpmRatio({
            series: [{  
                name: 'NPM Ratio',
                data: npm_ratio_dataset,
            }],            
        })
        setnpmTick({
            "Series1": npm_ratio_tickers,
        })

        setProfitability({
            series: [{  
                name: 'Profitability Score',
                data: prof_score_dataset,
            }], 
        })
        setProfTick({
            "Series1": prof_score_tickers,
        })

        setLoading(false)
        return (() => {})
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                    Following previous sections on profitability and solvency ratios, we now shift our focus to profitability ratios.
                    Profit margins are metrics of a company's financial health and performance, reflecting its ability to generate
                    profit over a given period. They are typically presented as percentages and are computed by dividing a
                    subset of profits by the revenue. Profit margins are crucial for investors and analysts as they provide insights
                    into a company's efficiency, competitiveness, and profitability. 
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>                    
                    Higher profit margins often indicate better financial health and effective cost management, making them a key 
                    indicator for evaluating a company's overall performance and potential investment value. The financial community 
                    primarily relies on three key profitability ratios: Gross Profit Margin (GPM), Operating Profit Margin (OPM), and 
                    Net Profit Margin (NPM) when evaluating a company's financial performance.
                </Typography>

                <Sectionheader head={'3.3.1 Metric Definition'} />

                <PrfDef />

                <Sectionheader head={'3.3.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The comparison of profit margins across different industries and businesses is a challenging undertaking, mainly due to the 
                    distinctive nature of each business. The wide range of profit margins observed in the {sector} sector makes it more difficult to determine 
                    if a particular business has a promising profit margin. Therefore, it is essential to adopt an approach to evaluate and comprehend the 
                    distribution of profit margins across the sector. It would allow a subtle analysis of the factors contributing to the profitability of a 
                    business.
                    {/* Profitability margin distributions are typically sensitive to extreme values, given their narrow fluctuation
                    range. Consequently, the calculation of distribution's statistics can be significantly affected by these 
                    outliers, potentially distorting the input values for scoring calculations. In alignment with our established 
                    methodology and to create a reliable benchmark, statistical parameters were computed using winsorized data, 
                    with upper and lower limits (not the percentiles) set at <Box fontWeight='fontWeightMedium' display='inline'> 3 
                    </Box> and <Box fontWeight='fontWeightMedium' display='inline'>-0.02</Box>, respectively. <Box fontWeight='fontWeightMedium' 
                    display='inline'>Figure 20</Box> to <Box fontWeight='fontWeightMedium' display='inline'>22</Box> offer a visual representation of profitability metrics in comparison to a 
                    company's winsorized 1-year returns. */}
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Profitability margin distributions are typically sensitive to extreme values, given their narrow (typically 0 
                    to 1.0) fluctuation range. Therefore, distribution's statistics can be significantly sensetive to these outliers. 
                    To create a reliable benchmark, statistical parameters were computed using winsorized (at 2% and 98%) data, 
                    respectively. <Box fontWeight='fontWeightMedium' display='inline'>Figure 20</Box> to <Box display='inline' 
                    fontWeight='fontWeightMedium'>22</Box> offer a visual representation of profitability metrics in comparison to 
                    a company's winsorized 1-year returns.
                </Typography>

                <MetricSector2 data={gpmRatio} name={''} ticks={gpmTick} colors={[c1, c2]} limits={c1m}/>

                <Figurecaption caption={'Figure 20: GPM vs. 1-year return across the sector'}/>

                <MetricSector2 data={opmRatio} name={''} ticks={opmTick} colors={[c2, c3]} limits={c2m}/>

                <Figurecaption caption={'Figure 21: OPM vs. 1-year return across the sector'}/>

                <MetricSector2 data={npmRatio} name={''} ticks={npmTick} colors={[c3, c1]} limits={c3m}/>

                <Figurecaption caption={'Figure 22: NPM vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    While high profitability ratios are generally considered positive indicators, there are situations where 
                    extremely high profitability ratios might not be great, and this could be due to various reasons. A company 
                    might achieve a very high profitability ratio through unsustainable practices. For example, neglecting 
                    essential maintenance or research and development to cut costs in the short term can lead to problems later 
                    on. In certain industries, extremely high profitability might be unusual. It could suggest that the company 
                    has a unique advantage or is operating in a niche market, but it might also raise questions about competition 
                    and the sustainability of such profitability in the industry context. The metric's statistics are presented 
                    in <Box fontWeight='fontWeightMedium' display='inline'>Table 5</Box>.

                    {/* <Box fontWeight='fontWeightMedium' display='inline'>Note: </Box> While high profitability ratios are generally 
                    considered positive indicators, there are situations where extremely high profitability ratios might not be great, 
                    and this could be due to various reasons. A company might achieve a very high profitability ratio through 
                    unsustainable practices. For example, neglecting essential maintenance or research and development to cut costs in 
                    the short term can lead to problems later on. In certain industries, extremely high profitability might be unusual. 
                    It could suggest that the company has a unique advantage or is operating in a niche market, but it might also raise 
                    questions about competition and the sustainability of such profitability in the industry context. */}
                </Typography>

                <Figurecaption caption={'Table 5: Statistics of profitability ratios distribution'} />

                <Box sx={{mb: '2%', border:1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats2 table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Based on the above values, readers can compare the financial performance of companies with sector-wide norms. 
                    For instance, an average company in the {sector} sector reports a gross profit margin ({lang1}) of <Box 
                    fontWeight='fontWeightMedium' display='inline'> ${ 1000 * Statistics['GPM'].median}</Box> for every $1,000 of 
                    revenue. At 75 percentile, operation profit margin of a compnay is <Box fontWeight='fontWeightMedium' 
                    display='inline'>{opm_times}</Box> times that of an average company. It can be concluded that across the sector, 
                    net income is roughly <Box fontWeight='fontWeightMedium' display='inline'>{npm_times}%</Box> of the operation income. 
                    Finally, it is typicall for a company in the sector to {lang2} <Box fontWeight='fontWeightMedium' display='inline'>
                    ${Statistics['NPM'].median}</Box> in net profit for of every dollar in earned in revenue.
                </Typography>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Comparing the median, <Box fontWeight='fontWeightMedium' display='inline'>{opv}</Box>, and market-cap-weighted 
                    average, <Box fontWeight='fontWeightMedium' display='inline'>{opm_wm}</Box>, will highlight the effect of the 
                    company's market capitalization on the distribution of net operational margin. Additionally, the difference 
                    between the mean and median, accompanied by the standard deviation <Box fontWeight='fontWeightMedium' 
                    display='inline'>{stds}</Box>, indicates the effects and signals magnitude of extreme values (both high and 
                    low) in this metric.
                </Typography>

                <Sectionheader head={'3.3.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The current scoring system aims to provide an objective comparison of these metrics across the sector. 
                    This system awards the 
                    higher margins with higher score to a point and after that slightly reduces the score for the companies that their profitability ratios 
                    put them in outlier zones. The composite profitability score, as a weighted average of each individual profitability score, versus one-year 
                    return of the companies in the sector is shown in <Box fontWeight='fontWeightMedium' display='inline'>Figure 23</Box>.
                </Typography>

                <ScoreSector series={profitability} labels={profTick} color={c2} limits={[chartweb.rtot.min, chartweb.rtot.max]}/>

                <Figurecaption caption={'Figure 23: Composite profitability score vs. 1-year return'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The top 10 companies with the highest composite profitability scores in the {sector} sector are shown 
                    in <Box fontWeight='fontWeightMedium' display='inline'>Figure 24</Box>. Ticker <Box fontWeight='fontWeightMedium' 
                    display='inline'>{highScore.names[0]}</Box> secured the top rank in the list, with {pron} composite score of 
                    <Box fontWeight='fontWeightMedium' display='inline'> {highScore.data[0]}</Box>. Tickers <Box fontWeight='fontWeightMedium' 
                    display='inline'> {highScore.names[1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[2]}
                    </Box> secured the second and third positions with total scores of <Box fontWeight='fontWeightMedium' display='inline'>
                    {highScore.data[1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{highScore.data[2]}</Box>, respectively.
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 24: Companies with highest total profitability score'} />

                <Sectionheader head={'3.3.4 Profitability Score: Industry Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Both the simple and market capitalization-weighted averages of the profitability scores for industries 
                    within the {sector} sector are presented in <Box fontWeight='fontWeightMedium' display='inline'>Table 
                        5.A</Box>. Readers can observe that {maxI} industry leads in the sector with the highest average 
                    profitability score of <Box fontWeight='fontWeightMedium' display='inline'>{maxS}</Box>. On the other 
                    hand, <Box fontWeight='fontWeightMedium' display='inline'>{minI}</Box> industry has the lowest average 
                    profitability score of <Box fontWeight='fontWeightMedium' display='inline'>{minS}</Box>.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 12, md:14}, fontWeight: '600', lineHeight: 1.5}}>
                    Table 5.A: Profitability ratios averages across the industries
                </Typography>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <IndustryAverageScore header={['Industry', 'Simple', 'Wighted']} data={items} />
                </Box>

                {/* <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Reader is refered to <Box fontWeight='fontWeightMedium' display='inline'>Tables ZZ and XY</Box> for a more detailed 
                    view of profitability ratios and their respective scores across the sector.
                </Typography>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['GPM', 'OPM', 'NPM']}/>
                </Box> */}

            </Box>
            }
        </div>
    )

}