import React from "react";
// import Layout from "../components/layout";
import { Box, Typography } from "@mui/material";

export function LiqDef() {
    return(
        <>
            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Current ratio (CUR): </Box>The current ratio serves as a straightforward measure of 
                liquidity, easily computed and understood. It assesses an organization's near-term solvency by dividing current assets by current 
                liabilities.
            </Typography>

            <Box sx={{ 
                    width: {xs: '95%', md:'90%'}, 
                    alignSelf: 'center' 
                    }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Current Ratio = Current Assets &frasl; Current Liabilities
                </Typography>
            </Box>

            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Quick ratio (QUR): </Box> The quick ratio, in contrast to the current ratio, 
                applies a more stringent criterion to assess liquidity. It restricts its calculation to a subset of current assets, comprising cash 
                and cash equivalents, along with accounts receivables, and eliminates certain items such as inventories and investments from consideration.
            </Typography>

            <Box sx={{
                width: {xs:'95%', md:'90%'}, 
                alignSelf: 'center' 
                }}>

                <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Quick Ratio = (Current Assets - Inventories) &frasl; Current Liabilities
                </Typography>
                    
            </Box>

            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Cash ratio (CAR): </Box> The cash ratio is a liquidity metric that takes a more rigorous 
                approach to assessing a company's liquidity. It focuses solely on the company's most liquid current assets, including cash and marketable 
                securities (cash equivalents), which are readily available to meet the company's short-term obligations. By narrowing thescope of assets under 
                consideration, the cash ratio offers a more precise measure of a company's ability tomeet its short-term financial obligations than other 
                liquidity ratios.
            </Typography>

            <Box sx={{
                width: {xs:'95%', md:'90%'}, 
                alignSelf: 'center'
                }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Cash Ratio = (Cash + Marketable Securities) &frasl; Current Liabilities
                </Typography>
            </Box>
        </>
    )
}

export function SolDef() {
    return(
        <>
        
            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Debt to Asset (D/A) ratio: </Box> D/A metric ratio serves as a 
                crucial indicator of a company's financial stability and risk, measuring its ability to meet long-term debt obligations. 
                In essence, the debt to asset ratio measures the proportion of a company's assets effectively owned by creditors, 
                representing the share of assets encumbered by debt obligations.
            </Typography>

            <Box sx={{ width: '90%', alignSelf: 'center' }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Debt to Asset Ratio =  Total Debt &frasl; Total Assets
                </Typography>
            </Box>

            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Debt-to-Equity (D/E) ratio: </Box> The D/E, also referred to as 
                “risk ratio”, is a metric that computes the proportion of total debt and financial liabilities relative to total shareholders' 
                equity. This ratio provides insight into a company's capital structure, indicating whether it leans more towards debt 
                or equity financing. Total debt includes short-term debt, long-term debt, and other fixed payment obligations that arise 
                during normal operating cycles for the company.
            </Typography>

            <Box sx={{ width: '90%', alignSelf: 'center' }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Debt to Equity Ratio = Total Debt &frasl; Shareholder’s Equity
                </Typography>
            </Box>

            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Debt-to-Capitalization (D/C) ratio: </Box> The D/C ratio is another 
                vital financial metric used to gauge a company's capital structure. It assesses the proportion of a company's capital 
                financed by debt, including both short-term and long-term liabilities. This ratio offers insights into the degree of 
                financial leverage a company utilizes to sustain its operations and investments.
            </Typography>

            <Box sx={{ width: '90%', alignSelf: 'center' }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Debt to Capitalization Ratio = Total Debt &frasl; (Total Debt + Shareholder's Equity)
                </Typography>
            </Box>
        </>
    )
}

export function PrfDef() {
    return(
        <>
            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{
                    fontSize: {xs: 16, md:18}, 
                    fontWeight: '300', 
                    lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Gross Profit Margin (GPM): </Box> Gross profit margin is a 
                financial metric that measures a company's profitability by calculating the percentage of revenue left after 
                deducting the cost of goods sold. A higher margin indicates better financial health and efficiency. It is useful 
                for comparing companies in the same industry, as it provides insights into their pricing strategies, production 
                costs, and supply chain management.
            </Typography>

            <Box sx={{ width: '90%', alignSelf: 'center' }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Gross Profit Margin =  Gross Profit &frasl; Revenue
                </Typography>
            </Box>

            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{
                    fontSize: {xs: 16, md:18}, 
                    fontWeight: '300', 
                    lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Operating Profit Margin (OPM): </Box> The operating profit 
                margin is a measure that indicates a company's profitability derived from core business activities after 
                subtracting operating expenses, such as salaries, leases, and advertising costs. It is expressed as a percentage 
                of operating profit to total revenue. OPM provides an accurate view of a company's ability to produce profits 
                from its primary revenue-generating operations, irrespective of other factors like taxes, interest, and one-time 
                gains or losses.
            </Typography>

            <Box sx={{ width: '90%', alignSelf: 'center' }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Operating Profit Margin = Operating Income &frasl; Revenue
                </Typography>
            </Box>
            
            <Typography
                component='div'
                paragraph
                textAlign={'justify'} 
                sx={{
                    fontSize: {xs: 16, md:18}, 
                    fontWeight: '300', 
                    lineHeight: 1.5}}>
                <Box fontWeight='fontWeightMedium' display='inline'>Net Profit Margin (NPM): </Box> Net profit margin is a metric 
                that measures a company's operating income, after deducting operating expenses such as wages, taxes, and depreciation, 
                as a percentage of total revenue. It provides insights into a company's ability to generate profits from its core 
                operations.
            </Typography>

            <Box sx={{ width: '90%', alignSelf: 'center' }}>
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Net Profit Margin = Net Profit &frasl; Revenue
                </Typography>
            </Box>
        </>
    )
}

export function EffDef() {}